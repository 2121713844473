import React, { useState } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import Demo from "../assets/img/demo.png";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import BlogPost from "../components/BlogPost";
import SectionTitle from "./SectionTitle";

const ProductCard = () => {
    const [items, setItems] = useState([
        {
            id: 1,
            title: "",
            imgSrc: "https://placeimg.com/640/480/any",
            imgAlt: "Card image cap",
            caption:
                "Harnessing the power of AI and single-cell transcriptomics, we unveil intricate cellular dynamics and drive transformative discoveries. With our cutting-edge solutions and expert team, we provide comprehensive support in experimental design, data analysis, and advanced insights. Join us on the forefront of personalized medicine as we unlock the secrets of cellular heterogeneity, enabling precise diagnoses, targeted treatments, and improved patient outcomes. Together, let's revolutionize healthcare by unraveling the hidden complexities within each cell, leading us to a healthier future.",
        },
        {
            id: 2,
            title: "Spatial ~omics",
            imgSrc: "https://placeimg.com/640/480/any",
            imgAlt: "Card image cap",
            caption:
                "sdsdsdsdsdsdAc aliquet vitae sem quis turpis neque. Nec ut urna consectetur vitae nunc sed elit lacus.",
        },
        {
            id: 3,
            title: "Histopathology",
            imgSrc: "https://placeimg.com/640/480/any",
            imgAlt: "Card image cap",
            caption:
                "Ac aliquet vitae sem quis turpis neque. Nec ut urna consectetur vitae nunc sed elit lacus.",
        },
    ]);

    return (
        <>
            <Container id="products">
                <Row className="justify-content-center py-5">
                    <Col lg="7">
                        <div class="d-flex flex-column align-items-center">
                            <SectionTitle
                                subTitle="Services"
                                title="Innovative Single-Cell, Omics, and Image Analysis Solutions"
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
            {/* Slider */}
            {/* Slider */}
            <div className="mt-5">
                <BlogPost />
                <div className="d-flex justify-content-center">
                    <Button
                        color="theme-blue"
                        className="px-4 py-2 rounded-pill mt-3 fw--700 fs--14 fw--700 ff--Inter"
                    >
                        <a href="https://www.deepmedai.com/Products" style={{ color: '#ffffff' }}>GO TO PAGE</a>
                    </Button>
                </div>
            </div>
        </>
    );
};

export default ProductCard;
