import React from "react";
import Header from "../components/Header/Header";
import HomeBg from "../assets/img/HomeBg_scRNA.jpg";
import Image1 from "../assets/img/Hist1.png";
import Image2 from "../assets/img/Hist2.png";
import Image3 from "../assets/img/Hist4.png";
import Image4 from "../assets/img/Hist11.png";
import "../assets/css/BlogPost.css"; // Import the CSS file for additional styling

function BlogPost() {
  return (
    <>
      <Header url={HomeBg} title={"AI-Powered Drug Repurposing (DM-Rep)"} />
      <div className="blog-container">
        <h2 className="blog-title">Drug Repurposing</h2>
        <p className="blog-intro">
	  DeepMedAI sets a new standard in AI-powered drug repurposing by seamlessly integrating diverse omics and imaging data. Our cutting-edge platform analyzes genomics, transcriptomics, proteomics, metabolomics, and imaging data, unveiling comprehensive molecular signatures and therapeutic opportunities. By harnessing the power of multi-omic and imaging analysis, DeepMedAI empowers researchers and pharmaceutical companies to accelerate drug repurposing and drive innovation in personalized medicine. Embrace the future of data-driven drug discovery with DeepMedAI.
        </p>

        <div className="section">
          <h3 className="section-title">Data Analysis</h3>
          <p className="section-text">
            We specialize in the analysis of single-cell data, including transcriptomics, epigenomics, proteomics, and spatial genomics. Our team of experts utilizes advanced algorithms and cutting-edge computational methodologies to extract meaningful insights from complex datasets.
          </p>
        </div>

        <div className="section">
          <h3 className="section-title">Experimental Design</h3>
          <p className="section-text">
            We collaborate closely with clients to design optimal experimental strategies for single-cell analysis. Our team provides guidance on sample preparation, experimental protocols, and data collection techniques to ensure high-quality data generation.
          </p>
        </div>

        <div className="section">
          <h3 className="section-title">Data Processing and Quality Control</h3>
          <p className="section-text">
          We employ robust data processing pipelines to preprocess and normalize single-cell data, ensuring accurate and reliable analysis. Our quality control measures identify and address potential sources of noise and bias, ensuring the integrity of the data.
          </p>
        </div>
        <div className="section">
          <h3 className="section-title">Differential Gene Expression Analysis</h3>
          <p className="section-text">
	  Our experts perform differential gene expression analysis to identify genes that are significantly upregulated or downregulated between different cell populations. This analysis helps uncover potential biomarkers and key regulatory genes associated with specific biological processes or diseases
          </p>
        </div>
        <div className="section">
          <h3 className="section-title">Visualization and Interpretation</h3>
          <p className="section-text">
	  We provide interactive visualizations and intuitive reports that facilitate the exploration and interpretation of single-cell data. Our visualizations enable researchers to identify patterns, explore gene expression profiles, and gain deeper insights into cellular dynamics.
          </p>
        </div>
        <div className="section">
          <h3 className="section-title">Pathway and Functional Analysis</h3>
          <p className="section-text">
	  We offer pathway and functional analysis to uncover biological pathways, gene networks, and functional annotations associated with specific cell populations or conditions. This analysis provides valuable insights into the underlying biological processes driving cellular behaviors.
          </p>
        </div>
        <div className="section">
          <h3 className="section-title">Integration of Multi-omics Data</h3>
          <p className="section-text">
	  We specialize in the integration of multi-omics data, such as single-cell transcriptomics and spatial genomics, to gain a comprehensive understanding of biological systems. This integration enables researchers to explore the spatial organization of cells and unravel complex interactions between different molecular layers.

          </p>
        </div>
        <div className="section">
          <h3 className="section-title">Consulting and Collaborative Research</h3>
          <p className="section-text">
Our team of experts provides consulting services and collaborates with researchers on complex projects. We offer guidance on study design, data analysis strategies, and result interpretation, ensuring the successful execution of single-cell analysis projects.
          </p>
        </div>
        <div className="section">
          <h3 className="section-title">Training and Workshops</h3>
          <p className="section-text">
We conduct training sessions and workshops to educate researchers and professionals about single-cell analysis techniques, methodologies, and best practices. These sessions enhance the skills and knowledge required to perform high-quality single-cell analysis.
          </p>
        </div>

      </div>
    </>
  );
}

export default BlogPost;
