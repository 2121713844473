import React from "react";
import Header from "../components/Header/Header";
import HomeBg from "../assets/img/HomeBg_hist.jpg";
import Image1 from "../assets/img/Hist1.png";
import Image2 from "../assets/img/Hist2.png";
import Image3 from "../assets/img/Hist4.png";
import Image4 from "../assets/img/Hist11.png";
import "../assets/css/BlogPost.css"; // Import the CSS file for additional styling

function BlogPost() {
  return (
    <>
      <Header url={HomeBg} title={"Blog Post"} />
      <div className="blog-container">
        <h2 className="blog-title">Welcome to Our Blog</h2>
        <p className="blog-intro">
          <span className="highlight">Lorem ipsum dolor sit amet,</span> consectetur adipiscing elit. Suspendisse
          euismod metus nec erat malesuada, sed maximus lorem euismod. Nunc
          faucibus feugiat enim, a sagittis elit semper a. Mauris vitae
          ullamcorper odio, <span className="italic">eget rutrum lacus.</span>
        </p>

        <div className="section">
          <h3 className="section-title">Section 1: Introduction</h3>
          <p className="section-text">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
            convallis tristique dapibus. Vivamus sit amet tortor sed arcu
            consectetur ullamcorper vitae et ligula.
          </p>
          <img src={Image1} alt="Image 1" className="section-image" />
          <p className="image-description">Image 1 description</p>
        </div>

        <div className="section">
          <h3 className="section-title">Section 2: Key Insights</h3>
          <p className="section-text">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac quam
            eget mi commodo venenatis. Curabitur at purus orci. Proin feugiat
            turpis a interdum vulputate.
          </p>
          <img src={Image2} alt="Image 2" className="section-image" />
          <p className="image-description">Image 2 description</p>
        </div>

        <div className="section">
          <h3 className="section-title">Section 3: Conclusion</h3>
          <p className="section-text">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
            ultrices enim a nisi tempor, in cursus risus consequat. Quisque
            aliquet tortor sit amet dui varius, in tristique felis semper.
          </p>
	  <img src={Image3} alt="Cell types" className="section-image" />
          <img src={Image4} alt="Image 3" className="section-image" />
          <p className="image-description">Image 3 description</p>
        </div>
      </div>
    </>
  );
}

export default BlogPost;
