import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import logo from "../../assets/img/logoFooter.png";
import LoginForm from "../../components/Login/LoginForm";

import Header from "../../components/Header/Header";
import HomeBg from "../../assets/img/HomeBg.png";
const Login = () => {
    return (
        <>
            {" "}
            <Header url={HomeBg} title={"User Login"} />
            <div className="login-main p-3 p-md-5 my-5  bg-white">
                <Row className="align-items-center">
                    <Col md={12} className="login-main__right">
                        <div className="d-flex justify-content-center">
                            <Link to="/" className="login-logo">
                                <img src={logo} className="img-fluid" />
                            </Link>
                        </div>
                        <hr />
                        <p className="text-center   fw--700 fs--48 ff--Inter">
                            Log in
                        </p>
                        <LoginForm />
                    </Col>
                </Row>
            </div>{" "}
        </>
    );
};

export default Login;
