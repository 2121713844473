const initialState = {
    nodes: [
        {
            id: Math.random().toString(16).slice(2),
            title: "S3 Raw Data",
            color: "LemonChiffon",
            content: [
                {
                    id: Math.random().toString(16).slice(2),
                    name: "basic analysis data",
                    type: "text"
                },
            ],
            position: { x: 40, y: 40 },
        },
        {
            id: Math.random().toString(16).slice(2),
            title: "S3 MODEL TRAINING",
            color: "LemonChiffon",
            content: [
                {
                    id: Math.random().toString(16).slice(2),
                    name: "compiling analysis data",
                    type: 'text'
                },
            ],
            position: { x: 370, y: 40 },
        },
    ],
    links: [],
    editNode: {},
};

let tempNodes = null;

export default function diagramReducer(
    state = initialState,
    { type, payload }
) {
    switch (type) {
        case "ADD_NODE":
            return {
                ...state,
                nodes: [...state.nodes, payload],
            };
        case "DELETE_NODE":
            tempNodes = JSON.parse(JSON.stringify(state.nodes));
            tempNodes = tempNodes.filter((tempNode) => tempNode.id !== payload);
            return {
                ...state,
                nodes: [...tempNodes],
            };
        case "UPDATE_NODE":
            tempNodes = JSON.parse(JSON.stringify(state.nodes));
            let tempNodeIndex = tempNodes.findIndex(
                (tempNode) => tempNode.id === payload.id
            );
            if (tempNodeIndex !== -1)
                tempNodes[tempNodeIndex] = {
                    ...tempNodes[tempNodeIndex],
                    ...payload,
                };
            return {
                ...state,
                nodes: [...tempNodes],
            };
        case "EDIT_NODE":
            return {
                ...state,
                editNode: payload,
            };
        case "ADD_LINK":
            if (
                state.links.find(
                    (link) =>
                        link.source === payload.source &&
                        link.target === payload.target
                )
            ) {
                return {
                    ...state,
                };
            }
            return {
                ...state,
                links: [...state.links, payload],
            };
        default:
            return {
                ...state,
            };
    }
}
