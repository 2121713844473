import {
    NodeModel as StormNodeModel,
    DefaultPortModel,
} from "@projectstorm/react-diagrams";

export class NodeModel extends StormNodeModel {
    constructor(options) {
        super({
            ...options,
            type: "ts-custom-node",
        });
        this.color = options.color || "White";
        this.title = options.title || "Node";
        this.content = options.content || [];
        this.source = options.source || false;
        this.inputs = options.inputs || [];
        this.outputs = options.outputs || [];
        this.deleteNode = options.deleteNode || null;
        this.editNode = options.editNode || null;

        // setup an in and out port
        if (this.content.length !== 0) {
            for (let i = 0; i < this.content.length; i++) {
                this.addPort(
                    new DefaultPortModel({
                        in: true,
                        name: `IN-${this.content[i].id}`,
                    })
                );
                this.addPort(
                    new DefaultPortModel({
                        in: false,
                        name: `OUT-${this.content[i].id}`,
                    })
                );
            }
        }
    }

    serialize() {
        return {
            ...super.serialize(),
            color: this.color,
            title: this.title,
            content: this.content,
            source: this.source,
            inputs: this.inputs,
            outputs: this.outputs,
            deleteNode: this.deleteNode,
            editNode: this.editNode,
        };
    }

    deserialize(event) {
        super.deserialize(event);
        this.color = event.data.color;
        this.title = event.data.title;
        this.content = event.data.content;
        this.source = event.data.source;
        this.inputs = event.data.inputs;
        this.outputs = event.data.outputs;
        this.deleteNode = event.data.deleteNode;
        this.editNode = event.data.editNode;
    }
}
