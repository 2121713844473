import React from "react";
import { Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";

const CustomField = (props) => {
    return (
        <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
                <InputGroupText className="login-main__right__custom-icon">
                    {props.icon}
                </InputGroupText>
            </InputGroupAddon>
            <Input
                type="text"
                className="login-main__right__custom-field"
                placeholder={props.text}
            ></Input>
        </InputGroup>
    );
};

export default CustomField;
