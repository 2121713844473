import * as React from "react";
import clsx from "clsx";
import { DiagramEngine, PortWidget } from "@projectstorm/react-diagrams-core";

class NodeFunctionWidget extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        console.log(props.node);
    }

    render() {
        return (
            <>
                {this.props.node.content.map((content, index) => (
                    <div
                        key={`key-${index}`}
                        className={clsx(
                            "custom-node-port",
                            "text-left",
                            "d-flex"
                        )}
                    >
                        {!this.props.node.source && (
                            <PortWidget
                                engine={this.props.engine}
                                port={this.props.node.getPort(
                                    `IN-${content.id}`
                                )}
                                className={clsx(
                                    "circle-porter",
                                    "circle-porter-in"
                                )}
                            >
                                <div className={clsx("circle-port")} />
                            </PortWidget>
                        )}
                        {content.name}
                        <span className="ml-auto">{content.type}</span>
                        <PortWidget
                            engine={this.props.engine}
                            port={this.props.node.getPort(`OUT-${content.id}`)}
                            className={clsx(
                                "circle-porter",
                                "circle-porter-out"
                            )}
                        >
                            <div className={clsx("circle-port")} />
                        </PortWidget>
                    </div>
                ))}
            </>
        );
    }
}

export class NodeWidget extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="custom-node">
                <div
                    className="custom-node-header"
                    style={{ backgroundColor: this.props.node.color }}
                >
                    {this.props.node.title}
                    <div className="ml-3">
                        <button
                            className="border rounded mr-1 bg-transparent"
                            onClick={() => this.props.node.editNode?.()}
                        >
                            <i className="fa fa-pen"></i>
                        </button>
                        <button
                            onClick={() => this.props.node.deleteNode?.()}
                            className="border rounded bg-transparent"
                        >
                            <i className="fa fa-trash"></i>
                        </button>
                    </div>
                </div>
                {this.props.node.content.length !== 0 && (
                    <NodeFunctionWidget
                        node={this.props.node}
                        engine={this.props.engine}
                    />
                )}
            </div>
        );
    }
}
