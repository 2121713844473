// import { TRUE } from "node-sass";
import React, { useState } from "react";
// import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";
import {
    Card,
    CardImg,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardText,
    Container,
    Row,
    Col,
} from "reactstrap";
import Demo from "../assets/img/demo.png";

import team_pic2 from "../assets/img/nt.jpeg";
import team_pic3 from "../assets/img/sl.png";
import team_pic4 from "../assets/img/pb.png";
import team_pic1 from "../assets/img/nb.jpg";
import team_pic5 from "../assets/img/ll.png";
import team_pic6 from "../assets/img/nt.jpeg";
// import ResearchGallery from "./Carousel/GalleryCarousel";
import TeamCarousel from "./Carousel/TeamCarousel";
import SectionTitle from "./SectionTitle";
function TeamCard() {
    const teamData = [
        {
            image: team_pic1,
            text: "Neha Bose, Managing Director",
        },
        {
            image: team_pic2,
            text: "Nishant Thakur, Founder & Chief Innovation Officer (CIO)",
        },
        {
            image: team_pic3,
            text: "Song Hua Lee, Co-founder & Key Program Manager",
        },
        {
            image: team_pic4,
            text: "Priya Bose, Director of Market Expansion",
        },
        {
            image: team_pic1,
            text: "Neha Bose, Managing Director",
        },
        {
            image: team_pic2,
            text: "Nishant Thakur, Founder & Chief Innovation Officer (CIO)",
        },
        {
            image: team_pic3,
            text: "Song Hua Lee, Co-founder & Key Program Manager",
        },
        {
            image: team_pic4,
            text: "Priya Bose, Director of Market Expansion",
        },
    ];
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 3, // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 2, // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1, // optional, default to 1.
        },
    };
    // const cards = [
    //     {
    //         image: "https://via.placeholder.com/400x200",
    //         title: "Card 1",
    //         subtitle: "Card 1 subtitle",
    //         text: "Card 1 description",
    //     },
    //     {
    //         image: "https://via.placeholder.com/400x200",
    //         title: "Card 2",
    //         subtitle: "Card 2 subtitle",
    //         text: "Card 2 description",
    //     },
    //     {
    //         image: "https://via.placeholder.com/400x200",
    //         title: "Card 3",
    //         subtitle: "Card 3 subtitle",
    //         text: "Card 3 description",
    //     },
    //     {
    //         image: "https://via.placeholder.com/400x200",
    //         title: "Card 4",
    //         subtitle: "Card 4 subtitle",
    //         text: "Card 4 description",
    //     },
    //     {
    //         image: "https://via.placeholder.com/400x200",
    //         title: "Card 5",
    //         subtitle: "Card 5 subtitle",
    //         text: "Card 5 description",
    //     },
    //     {
    //         image: "https://via.placeholder.com/400x200",
    //         title: "Card 6",
    //         subtitle: "Card 6 subtitle",
    //         text: "Card 6 description",
    //     },
    //     {
    //         image: "https://via.placeholder.com/400x200",
    //         title: "Card 7",
    //         subtitle: "Card 7 subtitle",
    //         text: "Card 7 description",
    //     },
    //     {
    //         image: "https://via.placeholder.com/400x200",
    //         title: "Card 8",
    //         subtitle: "Card 8 subtitle",
    //         text: "Card 8 description",
    //     },
    //     {
    //         image: "https://via.placeholder.com/400x200",
    //         title: "Card 9",
    //         subtitle: "Card 9 subtitle",
    //         text: "Card 9 description",
    //     },
    //     {
    //         image: "https://via.placeholder.com/400x200",
    //         title: "Card 10",
    //         subtitle: "Card 10 subtitle",
    //         text: "Card 10 description",
    //     },
    //     {
    //         image: "https://via.placeholder.com/400x200",
    //         title: "Card 11",
    //         subtitle: "Card 11 subtitle",
    //         text: "Card 11 description",
    //     },
    //     {
    //         image: "https://via.placeholder.com/400x200",
    //         title: "Card 12",
    //         subtitle: "Card 12 subtitle",
    //         text: "Card 12 description",
    //     },
    // ];

    return (
        <>
            <Container id="teams">
                <Row className="justify-content-center py-5">
                    <Col lg="7" className="pt-5">
                        <div class="d-flex flex-column align-items-center">
                            <SectionTitle
                                subTitle="Our Team"
                                title="Meet our super friendly Team of DeepMedAI"
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
            <div className="team__carousel my-4">
                <TeamCarousel teamData={teamData} />
            </div>
        </>
    );
}

export default TeamCard;
