export const addNode = (payload) => {
    return {
        type: "ADD_NODE",
        payload: { id: Math.random().toString(16).slice(2), ...payload },
    };
};
export const deleteNode = (payload) => {
    return {
        type: "DELETE_NODE",
        payload,
    };
};
export const updateNode = (payload) => {
    return {
        type: "UPDATE_NODE",
        payload,
    };
};
export const editNode = (payload) => {
    return {
        type: "EDIT_NODE",
        payload,
    };
};

export const resetEditMode = () => {
    return {
        type: "EDIT_NODE",
        payload: {},
    };
}

// LINKS
export const addLink = (payload) => {
    return {
        type: "ADD_LINK",
        payload,
    };
};