import Footer from "../components/Footer/Footer";
import NavbarComp from "../components/Header/NavbarComp";
const Auth = (props) => {
    return (
        <div className="bg-main">
            {props.children}
            <Footer />
        </div>
        // <div className="d-flex flex-column min-h-screen bg-main">
        //     {/* <div className="navbar-bg pb-3">
        //         <NavbarComp />
        //     </div> */}
        //     <div className="my-auto w-100">{props.children}</div>
        //     <Footer />
        // </div>
    );
};

export default Auth;
