import React from "react";
import { Button, Form } from "reactstrap";
import CustomField from "./CustomField";

const LoginForm = () => {
    return (
        <Form>
            <CustomField icon={<i class="fas fa-envelope"></i>} text="Email" />
            <CustomField icon={<i class="fas fa-lock"></i>} text="Password" />
            <Button
                type="submit"
                color="primary"
                className="btn-block login-main__right__submit-btn"
            >
                Login
            </Button>
        </Form>
    );
};

export default LoginForm;
