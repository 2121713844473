import React from "react";
import { Link } from "react-router-dom";
import {
    Card,
    CardImg,
    CardBody,
    CardTitle,
    Button,
    Row,
    Col,
} from "reactstrap";
import items from "../config/Items";

const BlogPost = () => {
    return (
        <>
            <Row className="d-flex justify-content-center m-0">
                {items?.map((item) => {
                    return (
                        <Col
                            xs="10"
                            sm="10"
                            md="6"
                            lg="4"
                            xl="3"
                            className="my-xl-0 my-3"
                        >
                            <Card className="mx-3 border-0 header-card">
                                {/*  */}
                                <div className="card-image-wrapper">
                                    <CardImg
                                        top
                                        // width="100%"
                                        // height="50%"
                                        src={item.imgSrc}
                                        alt={item.imgAlt}
                                        className="card-image"
                                    />
                                </div>
                                <CardBody>
                                    <CardTitle
                                        tag="h5"
                                        className="ff--Inter fw--700 fs--24 text-center"
                                    >
                                        {item.title}
                                    </CardTitle>

                                    <p className="m-0 mt-5 ff--Inter fw--400 fs--18 color-666666">
                                        {item.caption}
                                    </p>
                                    <Link
                                        to={{
                                            pathname: `/product-details/${item.id}`,
                                            state: item,
                                        }}
                                        className="text-dark fs--17 mt-4 d-block fw--700 ff--Inter"
                                    >
                                        <u>Read more</u>
                                    </Link>
                                </CardBody>
                            </Card>
                        </Col>
                    );
                })}
            </Row>
        </>
    );
};

export default BlogPost;
