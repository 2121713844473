import React from "react";
import { Button, Col, Container, Row } from "reactstrap";
import Carousel from "react-multi-carousel";
import img1 from "../assets/img/img1.png";
import img2 from "../assets/img/img2.png";
import img3 from "../assets/img/img3.png";
import img4 from "../assets/img/img4.png";
import img5 from "../assets/img/img5.png";
import SectionTitle from "./SectionTitle";

const PreliminaryResearch = () => {
    const images = [
        {
            url1: "https://picsum.photos/seed/a/1600/900",
            url2: "https://picsum.photos/seed/a/1600/900",
            url3: "https://picsum.photos/seed/a/1600/900",
        },
    ];

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
            slidesToSlide: 1, // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
            slidesToSlide: 1, // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1, // optional, default to 1.
        },
    };

    return (
        <>
            <Container>
                <Row className="justify-content-center">
                    <Col lg="7" className="py-5">
                        <div class="d-flex flex-column align-items-center">
                            <SectionTitle
                                // subTitle="Welcome"
                                title="Comprehensive preliminary research before getting started"
	    description={<p className="text-justify">
        <strong>We Dive Deep</strong>, company committed to providing thorough and comprehensive preliminary research before starting any project. Our team ensures that we have a complete understanding of our client's needs and goals, and are equipped with the necessary knowledge and information to deliver successful outcomes. By diving deep into our research, we set a strong foundation for our projects and ensure that we provide the best possible results for our clients.
    </p>}
                            />
                            <Button
                                color="theme-blue"
                                className="px-4 py-2 rounded-pill mt-3 fw--700 fs--14 fw--700 ff--Inter"
                            >
                                LET'S TALK
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Container>

            <Container fluid className="pt-5">
                <Row className="pt-5">
                    <Col xl={1} className="pl-0 d-none d-xl-block">
                        <img src={img4} className="w-100 h-100" />
                    </Col>
                    <Col sm={6} xl={5}>
                        <Row>
                            <Col xs="12">
                                <img src={img1} className="w-100" />
                            </Col>
                            <Col xs="12" className="mt-4">
                                <img src={img2} className="w-100" />
                            </Col>
                        </Row>
                    </Col>
                    <Col className="mt-4 mt-sm-0" sm={6} xl={5}>
                        <img
                            src={img3}
                            alt=""
                            className="w-100 h-100"
                            style={{ objectFit: "cover" }}
                        />
                    </Col>
                    <Col xl={1} className="pr-0 d-none d-xl-block">
                        <img src={img4} className="w-100 h-100" />
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default PreliminaryResearch;
