import React from "react";
import {
    Button,
    Col,
    Container,
    Row,
    Card,
    CardImg,
    CardText,
    CardBody,
    CardTitle,
    CardSubtitle,
} from "reactstrap";
import Message from "../assets/img/messages.png";
import PersonMod from "../assets/img/singlecell.gif"
import Contact from "../assets/img/contact.png";
import Signal from "../assets/img/signal.png";
import SectionTitle from "./SectionTitle";

const AboutCard = () => {
    let cardData = [
        {
            title: "Personalised models",
            description:
		"Unlock the full potential of your data with DeepMedAI. We offer cutting-edge, AI-powered single-cell and bulk data analysis, fully customized to meet your specific needs. At DeepMedAI, you’re at the helm—choose the analyses that matter most to your business, and we’ll deliver precise, targeted insights in easy-to-understand reports. Partner with us to transform your data into actionable intelligence that drives informed decisions and fuels your success. With DeepMedAI, your data isn't just analyzed; it’s elevated to deliver the results you need.",
            img: Message,
        },
        {
            title: "Spatial omics innovators",
            description:
		"Transform your approach to spatial omics with DeepMedAI. Our advanced solutions convert complex biological data into clear, actionable insights, uncovering the intricate cellular interactions that drive innovation in disease research and drug development. Using proprietary algorithms and the expertise of our dedicated team, we maximize the value of your data, empowering breakthroughs in precision medicine and biological research. Partner with DeepMedAI to harness the full power of spatial analytics and lead the way in the future of healthcare and science.",
            img: Contact,
        },
	            {
            title: "Multimodal analysis",
            description:
		"DeepMedAI unleashes the power of multimodal analysis, revealing profound insights from diverse biological data. Our advanced algorithms and AI-driven approaches extract meaningful information, uncover hidden connections, and drive groundbreaking discoveries. Experience the transformative potential of multimodal analysis with DeepMedAI's cutting-edge solutions, revolutionizing the way we understand biology.",
            img: Signal,
        },
    ];
    return (
        <>
            <Container id="about-us">
                <Row className="justify-content-center py-5">
                    <Col lg="7" className="pt-5">
                        <div class="d-flex flex-column align-items-center">
                            <SectionTitle
                                subTitle="About us"
                                title="Unlocking the Power of Your Data through Advanced Analytics"
                                description={<p className="text-justify">DeepMedAI leverages advanced imaging and single-cell data analysis to extract valuable insights from biological data, advancing precision medicine, drug discovery, and disease diagnosis. Our data analytics solutions are empowering healthcare professionals to provide better patient care and improve outcomes</p>}
                            />
                            <Button
                                color="theme-blue"
                                className="px-4 py-2 rounded-pill mt-3 fw--700 fs--14 fw--700 ff--Inter"
                            >
                                LET'S TALK
                            </Button>
                        </div>
                    </Col>
                </Row>
                <Row className="justify-content-center py-5">
                    {cardData.map((card) => (
                        <Col md={5} xl={4} className="my-`xl-0 my-3">
			     <a href={card.link} target="_blank" rel="noopener noreferrer">
                            <Card className="shadow-sm about-card h-100 ">
                                <CardBody className="mt-4 mb-2">
                                    <img src={card.img} alt="Message" />
                                    <CardTitle
                                        tag="h3"
                                        className="text-dark mt-5 fw--600 ff--Roboto fs--27"
                                    >
                                        {card.title}
                                    </CardTitle>

                                    <CardText className="fw--400 mt-3 fs--18 ff--inner text-justify color-666666">
                                        {card.description}
                                    </CardText>
                                    {/* <Button>Button</Button> */}
                                </CardBody>
                            </Card>
			    </a>
                        </Col>
                    ))}
                </Row>
            </Container>
        </>
    );
};

export default AboutCard;
