import {
    Card,
    CardBody,
    CardText,
    CardTitle,
    Col,
    Container,
    Row,
} from "reactstrap";
import AboutCard from "../components/AboutCard";
import PaeliminaryResearch from "../components/PreliminaryResearch";
import ProductCards from "../components/ProductCards";
import TeamCard from "../components/TeamCard";
import DemoGallery from "../components/DemoGallery";
import Header from "../components/Header/Header";
import HomeBg from "../assets/img/HomeBg.png";
import personalize from "../assets/img/personalize.png";
import dataSecurity from "../assets/img/dataSecurity.png";
import multimodel from "../assets/img/multimodel.png";
import SectionTitle from "../components/SectionTitle";

function Landing() {
    let TopcardData = [
    ];
    return (
        <>
            <Header url={HomeBg} title={"Converging\nBiology"} />
            <AboutCard />
            <Container className="pt-5">
                {/* <small className="section-sub-title text-uppercase ff--Inter text-theme-paragraph fw--400 fs--14 mt-5 d-flex justify-content-center">
                    Welcome
                </small> */}
                <Row className="justify-content-center pt-4">
                    {TopcardData.map((card) => (
                        <Col md={6} xl={4} className="my-xl-0 my-3">
                            <Card className="shadow-sm header-card h-100">
                                <CardBody className="mt-4 mb-2">
                                    <img
                                        src={card.img}
                                        alt="Message"
                                        width={70}
                                    />
                                    <CardTitle
                                        tag="h3"
                                        className="text-dark mt-3 fw--700 ff--Roboto fs--24"
                                    >
                                        {card.title}
                                    </CardTitle>

                                    <CardText className="fw--400 mt-2 fs--18 ff--inter text-justify color-666666">
                                        {card.description}
                                    </CardText>
                                    {/* <Button>Button</Button> */}
                                </CardBody>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
<PaeliminaryResearch />
            <ProductCards />
            <TeamCard />
            <DemoGallery />
        </>
    );
}

export default Landing;
