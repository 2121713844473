import LandingPage from "../views/Landing";
import Login from "../views/auth/Login";
import ProductDetail from "../views/ProductDetail";
import Pipeline from "../views/Pipeline";
import Privacy from "../views/Privacy";
import PersonMed from "../views/PersonMed";
import scRNAseq from "../views/scRNAseq";
import SpatialOmics from "../views/SpatialOmics";
import DrugDesign from "../views/DrugDesign";
import Histopath from "../views/Histopath";
import Products from "../views/Products";
import DrugRepurposing from "../views/DrugRepurposing"
let routes = [
    {
        path: "/login",
        component: Login,
        layout: "auth",
    },
    {
        path: "/product-details/:id",
        component: ProductDetail,
        layout: "main",
    },
    {
        path: "/scRNAseq",
        component: scRNAseq,
        layout: "main",
    },
     { path: "/SpatialOmics",
        component: SpatialOmics,
        layout: "main",
    },
    {
        path: "/pipeline",
        component: Pipeline,
        layout: "main",
    },
    { path: "/", component: LandingPage, layout: "main" },
    {
        path: "/privacyPolicy",
        component: Privacy,
        layout: "main",
    },
    {
        path: "/PersonMed",
        component: PersonMed,
        layout: "main",
    },
	{
        path: "/Histopath",
        component: Histopath,
        layout: "main",
    },
	    {
        path: "/DrugDesign",
        component: DrugDesign,
        layout: "main",
    },

            {
        path: "/DrugRepurposing",
        component: DrugRepurposing,
        layout: "main",
    },
            {
        path: "/Products",
        component: Products,
        layout: "main",
    },
];
export default routes;
