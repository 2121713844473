import React from "react";
import { Col, Container, Row } from "reactstrap";
import GalleryCarousel from "./Carousel/GalleryCarousel";
import gallery_pic1 from "../assets/img/gallery_pic1.png";
import gallery_pic2 from "../assets/img/DM-SC.gif";
import gallery_pic3 from "../assets/img/DM-SO-SPATIAL_OMICS.gif";
import gallery_pic4 from "../assets/img/gallery_pic4.png";
import gallery_pic5 from "../assets/img/Histo2.gif";
import gallery_pic6 from "../assets/img/gallery_pic6.png";
import SectionTitle from "./SectionTitle";

function DemoGallery() {
    const teamData = [
        {
            image: gallery_pic2,
            text: <a href="http://www.deepmedai.com/scRNAseq">Single Cell analysis</a>,
        },
	 {
            image: gallery_pic3,
            text: <a href="http://www.deepmedai.com/SpatialOmics">Spatial omics</a>,
        },
        {
            image: gallery_pic5,
            text: <a href="http://www.deepmedai.com/Histopath"> Histopathology</a>,
        },
        {
            image: gallery_pic4,
            text: <a href="http://www.deepmedai.com/PersonMed">Precision oncology</a>,
        },
    ];
    return (
        <>
            <Container id="demo">
                <Row className="justify-content-center py-5">
                    <Col lg="5=7" className="pt-5">
                        <div class="d-flex flex-column align-items-center">
                            <SectionTitle
                                subTitle="DEMO"
                                title="Explore the product gallery"
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
            <div className="demo__carousel my-4">
                <GalleryCarousel teamData={teamData} />
            </div>
        </>
    );
}

export default DemoGallery;
