import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Col, Container, Row, Card, CardBody, CardTitle, CardText } from "reactstrap";
import Header from "../components/Header/Header";
import HomeBg from "../assets/img/HomeBg.png";
import mockProductDatabase from "./ProductItems";

const Flashcard = ({ frontContent, backContent, image }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <Card className="flashcard mb-3">
      <CardBody className={`flashcard-inner ${isFlipped ? 'is-flipped' : ''}`}>
        <div className="flashcard-front">
          {image && <img src={image} alt={frontContent} className="flashcard-image mb-2" />}
          <CardTitle tag="h5" className="text-center mb-3">{frontContent}</CardTitle>
          <Button color="primary" onClick={handleFlip} className="mt-auto">
            Learn More
          </Button>
        </div>
        <div className="flashcard-back">
          <CardText className="text-center mb-3">{backContent}</CardText>
          <Button color="secondary" onClick={handleFlip} className="mt-auto">
            Back to Front
          </Button>
        </div>
      </CardBody>
    </Card>
  );
};

const ProductDetail = () => {
    console.log("ProductDetail component is rendering");
    const [productData, setProductData] = useState(null);
    const { id } = useParams();

    useEffect(() => {
	console.log("ID from params:", id);
        const fetchedProduct = mockProductDatabase[id];
	console.log("Fetched product:", fetchedProduct);
        if (fetchedProduct) {
            setProductData(fetchedProduct);
        } else {
            console.error("Product not found");
            // Handle the error - maybe redirect to a 404 page
        }
        window.scrollTo(0, 0);
    }, [id]);

    if (!productData) return <div>Loading...</div>;

    return (
        <div className="product-detail">
            <Header url={productData.imgSrc} title={productData.title} />

            <Container className="pt-5">
                <div className="position-relative">
                    <img src={productData.imgSrc} className="w-100 rounded shadow product-image" alt={productData.title} />
                    <div className="position-absolute ff--Inter text-white product-title">
                        {productData.title}
                    </div>
                </div>
            </Container>

            <div className="product-main pb-3 mt-4">
                <Container>
                    <h2 className="text-center mb-4">Product Overview</h2>
                    <p className="text-center mb-5">{productData.description}</p>
                    
                    <h3 className="mb-3">Key Features</h3>
                    <ul className="key-features mb-5">
                        {productData.keyFeatures.map((feature, index) => (
                            <li key={index}>{feature}</li>
                        ))}
                    </ul>

                    <h3 className="mb-3">Detailed Features</h3>
                    <Row className="mt-md-5 mb-5">
                        {productData.cardsContent.map((feature, index) => (
                            <Col md={6} lg={3} className="py-1" key={index}>
                                <Flashcard
                                    frontContent={feature.title}
                                    backContent={feature.description}
                                    image={feature.image}
                                />
                            </Col>
                        ))}
                    </Row>

                    <div className="d-flex justify-content-center">
                        <Button
                            color="primary"
                            href={`/pipeline/${productData.id}`}
                            className="product-main__btn d-inline-flex m-auto px-5"
                        >
                            Try Demo
                        </Button>
                    </div>
                </Container>
            </div>
        </div>
    );
};

export default ProductDetail;
