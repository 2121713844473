import React, { useState } from "react";
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    Container,
} from "reactstrap";
import logo from "../../assets/img/LogoTaglineWhiteTxt.png";
import { Link } from "react-scroll";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

let links = [
    { to: "home", text: "Home" },
    {
        to: "about-us",
        text: "About Us",
    },
    { to: "products", text: "Products" },
    { to: "teams", text: "Team" },
    { to: "demo", text: "Demo" },
    { href: "mailto:hello@deepmedai.com", text: "Contact" },
    { href: "/login", text: "Login" },
];

const NavbarComp = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const location = useLocation();
    return (
        <Navbar className="pt-4" light expand="lg">
            <Container fluid>
                <NavbarBrand>
                    <RouterLink to="/" className="d-flex align-items-center">
                        <img src={logo} className="header-main__logo" />
                    </RouterLink>
                </NavbarBrand>
                <NavbarToggler className="text-white" onClick={toggle}>
                    <svg
                        width="24"
                        height="22"
                        viewBox="0 0 24 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0 2C0 1.60218 0.158036 1.22064 0.43934 0.93934C0.720645 0.658036 1.10218 0.5 1.5 0.5H22.5C22.8978 0.5 23.2794 0.658036 23.5607 0.93934C23.842 1.22064 24 1.60218 24 2C24 2.39782 23.842 2.77936 23.5607 3.06066C23.2794 3.34196 22.8978 3.5 22.5 3.5H1.5C1.10218 3.5 0.720645 3.34196 0.43934 3.06066C0.158036 2.77936 0 2.39782 0 2Z"
                            fill="white"
                        />
                        <path
                            d="M0 20C0 19.6022 0.158036 19.2206 0.43934 18.9393C0.720645 18.658 1.10218 18.5 1.5 18.5H22.5C22.8978 18.5 23.2794 18.658 23.5607 18.9393C23.842 19.2206 24 19.6022 24 20C24 20.3978 23.842 20.7794 23.5607 21.0607C23.2794 21.342 22.8978 21.5 22.5 21.5H1.5C1.10218 21.5 0.720645 21.342 0.43934 21.0607C0.158036 20.7794 0 20.3978 0 20Z"
                            fill="white"
                        />
                        <path
                            d="M10.5 9.5C10.1022 9.5 9.72064 9.65804 9.43934 9.93934C9.15804 10.2206 9 10.6022 9 11C9 11.3978 9.15804 11.7794 9.43934 12.0607C9.72064 12.342 10.1022 12.5 10.5 12.5H22.5C22.8978 12.5 23.2794 12.342 23.5607 12.0607C23.842 11.7794 24 11.3978 24 11C24 10.6022 23.842 10.2206 23.5607 9.93934C23.2794 9.65804 22.8978 9.5 22.5 9.5H10.5Z"
                            fill="white"
                        />
                    </svg>
                </NavbarToggler>
                <Collapse isOpen={isOpen} navbar>
                    <Nav className="ml-auto text-white" navbar>
                        {links.map((link) => (
                            <NavItem key={link.text} className="ml-3">
                                {link.to && location.pathname === "/" ? (
                                    <NavLink
                                        tag={Link}
                                        to={link.to}
                                        activeClass="active"
                                        spy={true}
                                        smooth={true}
                                        duration={500}
                                        className="cursor-pointer"
                                    >
                                        <small className="text-white mb-0 fw--700 ff--Inter text-uppercase">
                                            {link.text}
                                        </small>
                                    </NavLink>
                                ) : link.to ? (
                                    <HashLink
                                        to={`/#${link.to}`}
                                        className="nav-link"
                                    >
                                        <small className="text-white mb-0 fw--700 ff--Inter text-uppercase">
                                            {link.text}
                                        </small>
                                    </HashLink>
                                ) : (
                                    <NavLink href={link.href}>
                                        <small className="text-white mb-0 fw--700 ff--Inter text-uppercase">
                                            {link.text}
                                        </small>
                                    </NavLink>
                                )}
                            </NavItem>
                        ))}
                    </Nav>
                </Collapse>
            </Container>
        </Navbar>
    );
};

export default NavbarComp;
