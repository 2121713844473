import React, { Component } from "react";
import Slider from "react-slick";
import Demo from "../../assets/img/demo.png";

const GalleryCarousel = ({ teamData }) => {
    const settings = {
        className: "center",
        centerMode: false,
        infinite: true,
        // centerPadding: "60px",
        slidesToShow: 4,
        speed: 500,

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 2,
                },
            },
        ],
    };

    return (
        // <div className="GalleryCarousel ">
        <Slider {...settings}>
            {teamData?.map((data, index) => {
                return (
                    <>
                        <div className="text-center px-3">
                            <div class="card-image-wrapper card-image-wrapper--sm" style={{ height: 'auto', overflow: 'hidden' }}>
                                <img
                                    src={data?.image}
                                    alt=""
                                    className="w-100 h-auto"
                                />
                            </div>
                            <p className="text-center ff--Roboto fw--400 fs--14 my-2">
                                {data?.text}
                            </p>
                        </div>
                    </>
                );
            })}
        </Slider>
        // </div>
    );
};
export default GalleryCarousel;
