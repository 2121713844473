import React from "react";
import Header from "../components/Header/Header";
import HomeBg from "../assets/img/HomeBg_spat.jpg";
import Image1 from "../assets/img/Hist1.png";
import Image2 from "../assets/img/Hist2.png";
import Image3 from "../assets/img/Hist4.png";
import Image4 from "../assets/img/Hist11.png";
import "../assets/css/BlogPost.css"; // Import the CSS file for additional styling

function BlogPost() {
  return (
    <>
      <Header url={HomeBg} title={"Spatial ~omics analysis (DC-Spat)"} />
      <div className="blog-container">
        <h2 className="blog-title">DC-Spat package</h2>
        <p className="blog-intro">

        </p>

        <div className="section">
          <h3 className="section-title">Spatial Data Processing</h3>
          <p className="section-text">
	  We process and analyze spatially resolved transcriptomics and imaging data, capturing the spatial distribution of gene expression patterns within tissues or cellular samples. Our advanced computational pipelines handle the unique challenges posed by spatial data, enabling accurate and efficient analysis.
          </p>
        </div>

        <div className="section">
          <h3 className="section-title">Spatial Transcriptomics</h3>
          <p className="section-text">
	  Our experts analyze spatial transcriptomics data, revealing the spatial arrangement of gene expression patterns. By overlaying gene expression data onto tissue images, we unravel the molecular heterogeneity and spatial relationships within complex biological samples.
          </p>
        </div>

        <div className="section">
          <h3 className="section-title">Spatial Visualization</h3>
          <p className="section-text">
	  We employ sophisticated visualization techniques to generate high-resolution spatial maps, allowing researchers to explore and interpret the spatial patterns of gene expression. Our visualizations provide valuable insights into cellular organization, cell-cell interactions, and tissue microenvironments.
          </p>
        </div>
        <div className="section">
          <h3 className="section-title">Spatial Pattern Identification</h3>
          <p className="section-text">
	  Leveraging advanced algorithms and image analysis techniques, we identify spatial patterns and clusters within the spatialOmics data. This helps uncover key biological features, such as hotspots, gradients, or spatially restricted gene expression patterns, providing critical insights into tissue architecture and function.
          </p>
        </div>
        <div className="section">
          <h3 className="section-title">Cellular Phenotyping</h3>
          <p className="section-text">
	  We perform cellular phenotyping analysis by integrating spatial transcriptomics data with cell type annotations. This allows for the identification and characterization of specific cell types and their spatial distribution within the tissue, providing a deeper understanding of tissue composition and cellular interactions.
          </p>
        </div>
        <div className="section">
          <h3 className="section-title">Spatial Genomics Integration</h3>
          <p className="section-text">
	  We integrate spatialOmics data with other genomic and proteomic datasets, enabling a comprehensive view of biological systems. By combining spatial information with other molecular layers, we uncover intricate relationships between spatial organization, gene expression, and cellular function.
          </p>
        </div>
        <div className="section">
          <h3 className="section-title">Spatial Biomarker Discovery</h3>
          <p className="section-text">
Our spatialOmics analysis uncovers potential spatial biomarkers associated with specific tissue regions, cell types, or pathological conditions. These biomarkers have the potential to revolutionize diagnostics, drug targeting, and personalized medicine by providing spatially resolved insights into disease mechanisms.
          </p>
        </div>
        <div className="section">
          <h3 className="section-title">Spatial Data Interpretation</h3>
          <p className="section-text">
	  We provide in-depth interpretation and analysis of spatialOmics results, translating complex data into meaningful biological insights. Our experts collaborate closely with researchers to understand the research context and guide the interpretation of spatial patterns and gene expression profiles.
          </p>
        </div>
        <div className="section">
          <p className="section-text">
	  At DeepMedAI, we leverage the power of SpatialOmics analysis to unravel the hidden complexities of tissue architecture and molecular interactions. Our services empower researchers and clinicians to gain spatially resolved insights, leading to breakthrough discoveries in fields such as cancer research, developmental biology, and neuroscience. Partner with DeepMedAI to unlock the spatial dimension of your data and pave the way for transformative advancements in spatial genomics and precision medicine.
          </p>
        </div>

      </div>
    </>
  );
}

export default BlogPost;
