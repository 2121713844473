// Root Reducer

import { combineReducers } from "redux";
import authUserReducer from "./authUser";
import diagramReducer from "./diagramReducer";

export let rootReducer = combineReducers({
    authUser: authUserReducer,
    diagram: diagramReducer,
});

export default rootReducer;
