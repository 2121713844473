import Footer from "../components/Footer/Footer";

const Main = (props) => {
    return (
        <div className="bg-main">
            {props.children}
            <Footer />
        </div>
    );
};

export default Main;
