import React, { Component } from "react";
import Slider from "react-slick";
import Demo from "../../assets/img/demo.png";

const TeamCarousel = ({ teamData }) => {
    const settings = {
        className: "center",
        centerMode: false,
        infinite: true,
        // centerPadding: "60px",
        slidesToShow: 5.5,
        speed: 500,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
        ],
    };
    // console.log(teamData, "temaData in GalleryCarousel");
    return (
        // <div className="GalleryCarousel ">
        <Slider {...settings}>
            {teamData?.map((data, index) => {
                return (
                    <>
                        <div className="text-center">
                            <img
                                src={data?.image}
                                alt=""
                                className="data-image"
                            />
                            <p className="text-left fw--400 mt-3 ff--Roboto fs--14">
                                {data?.text}
                            </p>
                        </div>
                    </>
                );
            })}
        </Slider>
        // </div>
    );
};
export default TeamCarousel;
