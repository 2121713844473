import React from 'react';

function Subsection({ title, children }) {
  return (
    <div>
      <h4 style={{ textAlign: 'left' }}>{title}</h4>
      {children}
    </div>
  );
}

export default Subsection;
