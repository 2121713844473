import * as React from "react";
import createEngine, {
    DefaultLinkModel,
    DiagramModel,
} from "@projectstorm/react-diagrams";
import { NodeFactory } from "./NodeFactory";
import { NodeModel } from "./NodeModel";
import { CanvasWidget } from "@projectstorm/react-canvas-core";
import { useDispatch, useSelector } from "react-redux";
import {
    addLink,
    deleteNode,
    editNode,
    updateNode,
} from "../../store/actions/diagramAction";

// create an instance of the engine with all the defaults
const engine = createEngine();
engine.getNodeFactories().registerFactory(new NodeFactory());

const Diagrams = () => {
    let { nodes, links } = useSelector((state) => state.diagram);
    let dispatch = useDispatch();
    let [isLoaded, setIsLoaded] = React.useState(false);
    let [isMouseDown, setIsMouseDown] = React.useState(false);
    let [movedNode, setMovedNode] = React.useState(null);
    const onMouseUp = (e) => {
        setIsMouseDown(false);
    };
    const onMouseDown = () => {
        setIsMouseDown(true);
    };

    React.useEffect(() => {
        if (isMouseDown === false && movedNode !== null) {
            let {
                entity: {
                    options: { id },
                    position,
                },
            } = movedNode;
            dispatch(
                updateNode({
                    id,
                    position: {
                        x: position.x,
                        y: position.y,
                    },
                })
            );
            setMovedNode(null);
        }
    }, [isMouseDown]);
    React.useEffect(() => {
        window.addEventListener("mousedown", onMouseDown);
        window.addEventListener("mouseup", onMouseUp);
        return () => {
            window.removeEventListener("mousedown", onMouseDown);
            window.removeEventListener("mouseup", onMouseUp);
        };
    }, []);
    React.useEffect(() => {
        let tempNodes = [];
        let tempLinks = [];
        nodes.map((node) => {
            const temp = new NodeModel({
                ...node,
                deleteNode: () => {
                    dispatch(deleteNode(node.id));
                },
                editNode: () => {
                    dispatch(editNode(node));
                },
            });
            temp.setPosition(node?.position?.x || 10, node?.position?.y || 10);
            tempNodes.push(temp);
            console.log(tempNodes);
        });
        links.forEach((link) => {
            let { source, target } = link;
            let sourcePort = null;
            let targetPort = null;
            tempNodes.forEach((tempNode) => {
                try {
                    let tempSourcePort = tempNode.getPort(source);
                    let tempTargetPort = tempNode.getPort(target);
                    if (tempSourcePort) {
                        sourcePort = tempSourcePort;
                    }
                    if (tempTargetPort) {
                        targetPort = tempTargetPort;
                    }
                } catch (err) {
                    console.log(err.message);
                }
            });
            let tempLink = new DefaultLinkModel();
            tempLink.setSourcePort(sourcePort);
            tempLink.setTargetPort(targetPort);
            tempLinks.push(tempLink);
        });

        const model = new DiagramModel();
        model.registerListener({
            linksUpdated: function (event) {
                window.setTimeout(() => {
                    dispatch(
                        addLink({
                            source: event?.link?.getSourcePort()?.getOptions()
                                ?.name,
                            target: event?.link?.getTargetPort()?.getOptions()
                                ?.name,
                        })
                    );
                }, 2500);
            },
        });
        let models = model.addAll(...tempNodes, ...tempLinks);
        models.forEach((item) => {
            item.registerListener({
                eventDidFire: (e) => {
                    let eventType = e["function"];
                    if (eventType === "positionChanged") {
                        setMovedNode(e);
                    }
                },
            });
        });
        console.log(models);
        console.log(model);
        engine.setModel(model);
        setIsLoaded(true);
    }, [nodes, links]);
    return isLoaded ? (
        <CanvasWidget className="diagram-container" engine={engine} />
    ) : (
        <></>
    );
};

export default Diagrams;
