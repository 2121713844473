import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./assets/css/style.css"

// Import CSS here
import "bootstrap/dist/css/bootstrap.min.css";
// import "./assets/css/style.css"
import "./assets/scss/custom.scss";
import "react-multi-carousel/lib/styles.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById("root")
);
