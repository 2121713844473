import card_pic1 from "../assets/img/singlecell.gif";
import card_pic2 from "../assets/img/MainPillerSpatial.gif";
import card_pic3 from "../assets/img/MainPillerPerson.gif";
import card_pic4 from "../assets/img/DM-IA.gif";
const items = [
    {
        id: "sc-omics",
        title: "Single-Cell omics",
        imgSrc: card_pic1,
        imgAlt: "Card image cap",
        list: ["scRNA", "seq", "tailored", "Clustering"],
        cardsContent: [
            "Comprehensive Data Analysis: Our advanced algorithms provide in-depth analysis of scRNA-seq data, revealing cellular heterogeneity and gene expression patterns.",
	    "Cell Type Identification: We accurately identify distinct cell types based on their unique gene expression profiles, enabling a deeper understanding of cellular function.",
	    "Gene Expression Profiling: Our analysis provides detailed information on gene expression levels in individual cells, uncovering key regulatory pathways and biomarkers.",
	    "Cellular Differentiation and Development: We study cellular differentiation trajectories and developmental processes, shedding light on cell fate decisions and disease mechanisms.",
	    "Single-Cell Trajectory Analysis: Our tools reconstruct cellular trajectories, revealing the dynamic changes in gene expression that occur during development or disease progression.",
	    "Spatial Transcriptomics Integration: We seamlessly integrate scRNA-seq data with spatial transcriptomics to understand the spatial distribution of cell types and gene expression patterns within tissues.",
	    "Customizable Analysis Pipelines: Our platform offers flexible analysis pipelines tailored to your specific research questions and data types, ensuring accurate and relevant results.",

	],
        caption:
            "Unleashing the power of AI and single-cell transcriptomics, we drive transformative discoveries and precise insights. Join us at the forefront of personalized medicine for a healthier future.",
    },
    {
        id: "Spatial-omics",
        title: "Spatial ~omics",
        imgSrc: card_pic2,
        imgAlt: "Card image cap",
        list: ["omics", "personalized", "aliquet vitae"],
        cardsContent: [
            "High-Resolution Spatial Profiling: Our advanced spatial-omics techniques provide precise localization of molecules within tissues, revealing intricate spatial relationships.",
	    "Multi-omics Integration: We integrate spatial transcriptomics, proteomics, and other molecular data to gain a comprehensive understanding of cellular interactions and tissue organization.",
	    "Spatial Gene Expression Analysis: Our analysis identifies spatial patterns of gene expression, revealing the functional organization of tissues and the underlying mechanisms of disease.",
	    "Cell-Cell Communication: We study the spatial interactions between cells, uncovering critical signaling pathways and regulatory networks involved in tissue development and disease progression.",
	    "Spatial Proteomics: Our spatial proteomics techniques enable the localization of proteins within tissues, providing insights into protein function and interactions.",
	    "Spatial Epigenomics: We analyze the spatial distribution of epigenetic modifications, such as DNA methylation and histone modifications, to understand how they contribute to gene expression and cellular identity.",
	    "Customized Spatial Analysis: Our platform offers flexible spatial analysis pipelines tailored to your specific research questions and data types, ensuring accurate and relevant results.",
	],
        caption:
            "Revolutionizing healthcare with AI-powered Spatial Omics analysis. Unveiling hidden patterns and intricate molecular landscapes for personalized medicine's future",
    },
    {
        id: "Person-Med",
        title: "Personalized Medicine",
        imgSrc: card_pic3,
        imgAlt: "Card image cap",
        list: ["Lorem", "Ipsum", "tissue samples"],
        cardsContent: [
            "Advanced Tissue Segmentation: Our proprietary algorithm accurately delineates tissue regions, ensuring precise analysis and reducing human error.",
            "Accurate Tumor Identification: We leverage deep learning to reliably detect and classify various tumor types, aiding in early diagnosis and treatment planning.",
            "Granular Tumor Subtyping: Our analysis goes beyond tumor type to identify specific subtypes, providing valuable insights into tumor behavior and prognosis.",
            "Patient Stratification: We utilize AI to stratify patients based on their tumor characteristics, enabling personalized treatment recommendations and improved outcomes.",
	    "Spatial Analysis for Tumor Progression: By analyzing the spatial distribution of cells and molecules within the tumor microenvironment, we predict tumor progression and potential therapeutic responses.",
	    "Patient Survival Prediction: Our models can estimate patient survival rates based on comprehensive histopathological analysis, aiding in risk assessment and treatment decisions.",
	    "Comprehensive Analysis: Our approach encompasses a wide range of histopathological analyses, including cell morphology, cellular markers, and microenvironmental interactions."
        ],
        caption:
            "Transforming Histopathology analysis with advanced AI. Enhancing diagnostic insights. Embrace the future of precision pathology with our cutting-edge solutions.",
    },
    {
	    id: "MO-DI",
        title: "Omics Fusion",
        imgSrc: card_pic4,
        imgAlt: "Card image cap",
        list: [
            "Rational drug",
            "quis turpis",
            "Target identification",
            "interdisciplinary",
            "lacus",
        ],
        cardsContent: [
            "Comprehensive Marker Databases: Our curated databases contain extensive information on known cellular and spatial markers, enabling accurate identification and characterization.",
	    "Advanced Marker Discovery: Our algorithms can identify novel markers based on gene expression patterns, protein localization, or other relevant features.",
	    "Spatial Marker Localization: Our spatial analysis techniques reveal the precise localization of markers within tissues, providing insights into their function and interactions.",
            "Marker-Based Cell Phenotyping: We utilize identified markers to classify cells into distinct phenotypic subtypes, aiding in the understanding of cellular heterogeneity and disease mechanisms.",
            "Spatial Marker-Based Tissue Zonation: We identify markers that are differentially expressed in different regions of tissues, allowing for the delineation of functional zones and the study of their interactions.",
            "Marker-Guided Cell Sorting: Our identified markers can be used to isolate specific cell populations for further analysis, such as single-cell RNA sequencing or functional assays.",
            "Cellular Marker Profiling: We analyze gene expression data to identify markers specific to different cell types, facilitating their isolation and study.",
	],
        caption:
            "Unify and analyze genomics, proteomics, metabolomics, and epigenomics data to present a detailed and integrative perspective on the intricate dynamics of biological systems.",
    },
];

export default items;
