import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from "reactstrap";
import Diagrams from "../components/CustomDiagramNodes/Diagrams";
import NavbarComp from "../components/Header/NavbarComp";
import {
    addNode,
    resetEditMode,
    updateNode,
} from "../store/actions/diagramAction";

const Pipeline = () => {
    let [isModalOpen, setIsModalOpen] = useState(false);
    let [isEditMode, setIsEditMode] = useState(false);
    let { nodes, editNode: editNodeObj } = useSelector(
        (state) => state.diagram
    );
    let dispatch = useDispatch();
    let [obj, setObj] = useState({
        title: "",
        color: "LemonChiffon",
        content: [
            { id: Math.random().toString(16).slice(2), name: "", type: "" },
        ],
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        if (isEditMode) {
            dispatch(updateNode(obj));
        } else {
            let lastNode = nodes.at(-1);
            let position = {
                x: 30,
                y: 30,
            };
            if (lastNode) {
                position.x = lastNode.position.x + 330;
                position.y = lastNode.position.y;
            }
            dispatch(addNode({ ...obj, position }));
        }
        setIsModalOpen(false);
        setObj({
            title: "",
            color: "LemonChiffon",
            content: [
                { id: Math.random().toString(16).slice(2), name: "", type: "" },
            ],
        });
    };

    const closeModal = () => {
        setIsModalOpen(false);
        dispatch(resetEditMode());
        setObj({
            title: "",
            color: "LemonChiffon",
            content: [
                { id: Math.random().toString(16).slice(2), name: "", type: "" },
            ],
        });
    };

    useEffect(() => {
        if (Object.keys(editNodeObj).length !== 0) {
            setIsEditMode(true);
            setObj(JSON.parse(JSON.stringify(editNodeObj)));
            setIsModalOpen(true);
        } else {
            setIsEditMode(false);
        }
    }, [editNodeObj]);

    return (
        <div>
            <div className="navbar-bg pb-3">
                <NavbarComp />
            </div>
            <Container fluid className="py-4">
                <div className="d-flex justify-content-end">
                    <Button color="primary" className="mr-2">
                        Submit
                    </Button>
                    <Button
                        color="primary"
                        onClick={() => setIsModalOpen(true)}
                    >
                        Add Diagram
                    </Button>
                </div>
            </Container>
            <Diagrams />
            <Modal isOpen={isModalOpen}>
                <ModalHeader toggle={closeModal}>
                    {isEditMode ? "Edit" : "Add"} Diagram
                </ModalHeader>
                <Form onSubmit={handleSubmit}>
                    <ModalBody>
                        <FormGroup>
                            <Label>Title</Label>
                            <Input
                                type="text"
                                value={obj.title}
                                name="title"
                                onChange={(e) => {
                                    setObj((prevState) => ({
                                        ...prevState,
                                        title: e.target.value,
                                    }));
                                }}
                            ></Input>
                        </FormGroup>
                        <FormGroup>
                            <Label className="font-weight-bold">List</Label>
                            {obj.content.map((content, index) => (
                                <Row
                                    key={index}
                                    className="align-items-end mb-2"
                                >
                                    <Col
                                        xs={
                                            obj.content.length === 1 ? "8" : "5"
                                        }
                                    >
                                        <Label>Field Name</Label>
                                        <Input
                                            type="text"
                                            value={content.name}
                                            onChange={(e) =>
                                                setObj((prevState) => {
                                                    prevState.content[
                                                        index
                                                    ].name = e.target.value;
                                                    return { ...prevState };
                                                })
                                            }
                                        ></Input>
                                    </Col>
                                    <Col
                                        xs={
                                            obj.content.length === 1 ? "4" : "5"
                                        }
                                    >
                                        <Label>Field Type</Label>
                                        <Input
                                            type="select"
                                            value={content.type}
                                            onChange={(e) =>
                                                setObj((prevState) => {
                                                    prevState.content[
                                                        index
                                                    ].type = e.target.value;
                                                    return { ...prevState };
                                                })
                                            }
                                        >
                                            <option value="">
                                                Select Type
                                            </option>
                                            <option value="image">Image</option>
                                            <option value="text">Text</option>
                                            <option value="tabular">
                                                Tabular
                                            </option>
                                        </Input>
                                    </Col>
                                    {obj.content.length > 1 && (
                                        <Col
                                            xs={2}
                                            className="d-flex justify-content-end"
                                        >
                                            <Button
                                                onClick={() => {
                                                    setObj((prevState) => {
                                                        let ps = JSON.parse(
                                                            JSON.stringify(
                                                                prevState
                                                            )
                                                        );
                                                        ps.content =
                                                            ps.content.filter(
                                                                (_, idx) =>
                                                                    idx !==
                                                                    index
                                                            );

                                                        return { ...ps };
                                                    });
                                                }}
                                                color="danger"
                                            >
                                                <i className="fa fa-trash"></i>
                                            </Button>
                                        </Col>
                                    )}
                                </Row>
                            ))}
                            <div className="d-flex mt-3 justify-content-end">
                                <Button
                                    onClick={() =>
                                        setObj((prevState) => ({
                                            ...prevState,
                                            content: [
                                                ...prevState.content,
                                                {
                                                    id: Math.random()
                                                        .toString(16)
                                                        .slice(2),
                                                    name: "",
                                                    type: "",
                                                },
                                            ],
                                        }))
                                    }
                                    color="primary"
                                >
                                    <i className="fa fa-plus"></i>
                                </Button>
                            </div>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit">
                            Save
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </div>
    );
};

export default Pipeline;
