import React from "react";
import { Col, Container, Row } from "reactstrap";
import NavbarComp from "./NavbarComp";
import RightTextCard from "./RightTextCard";

const Header = (props) => {
    return (
        <div
            id="home"
            className="header-main d-flex flex-column"
            style={{
                backgroundImage: `url(${props.url}), linear-gradient(rgba(0,0,0,0.45),rgba(0,0,0,0.45))`,
            }}
        >
            <NavbarComp />
            <Container fluid className="my-auto">
                <Container fluid>
                    <Row className="align-items-center justify-content-center justify-content-md-start">
                        <Col md={6}></Col>
                        {/* <Col md={1} className="d-none d-lg-block">
                            <div className="d-flex">
                                <div className="d-flex align-items-center text-white flex-column">
                                    <span className="fw--400 fs--18 text-white ff--Inter">
                                        01
                                    </span>
                                    <span className="pipe mt-3"></span>
                                    <span className="fw--400 fs--18 text-white ff--Inter mt-3">
                                        02
                                    </span>
                                    <span className="fw--400 fs--18 text-white ff--Inter mt-3">
                                        03
                                    </span>
                                </div>
                            </div>
                        </Col> */}
                        <Col md={6} lg={6} xl={6} className="header-main__left">
                            <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                                <h1
                                    className="text-uppercase text-center text-md-left ff--Six-Caps fs--170 text-white "
                                    style={{ whiteSpace: "pre-wrap" }}
                                >
                                    {/* Converging
                                    <br />
                                    Biology */}
                                    {props.title}
                                </h1>
                            </div>
                        </Col>
                        {/* <Col
                            sm={8}
                            md={6}
                            lg={5}
                            xl={3}
                            className="header-main__right"
                        >
                            <div className="mt-md-4 mt-0 pb-3">
                                <RightTextCard
                                    heading="Personalised data analysis"
                                    text="Sed quis massa phasellus id nunc velit bibendum."
                                    active={false}
                                />
                                <RightTextCard
                                    heading="Data Security and control"
                                    text="Molestie condimentum faucibus molestie."
                                    active={true}
                                />
                                <RightTextCard
                                    heading="Multimodal analysis"
                                    text="Egestas id pretium phasellus ante ac facilisis
                                ut massa."
                                    active={false}
                                />
                            </div>
                        </Col> */}
                    </Row>
                </Container>
            </Container>
        </div>
    );
};

export default Header;
