import React from "react";
import { Col, Container, Nav, NavItem, Row, NavLink } from "reactstrap";
import logo from "../../assets/img/logoFooter.png";
import { Link } from "react-scroll";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

let links = [
    { to: "home", text: "Home" },
    {
        to: "about-us",
        text: "About Us",
    },
    { to: "products", text: "Products" },
    { to: "teams", text: "Team" },
    { to: "demo", text: "Demo" },
    { href: "mailto:nishant.thakur@deepmedai.com", text: "Contact" },
    { href: "/login", text: "Login" },
    { href: "/privacyPolicy", text: "Privacy Policy" },
];
const Footer = () => {
    const location = useLocation();

    return (
        <div className="footer-main py-5 px-2 px-md-5 text-dark">
            <Container>
                <Row>
                    <Col md={4}>
                        <div>
                            <img className="ml-5 mb-3 footer-logo" src={logo} />
                            <div className="footer-main__left-text">
                                <p className="fs--18 text-justify">
                                    Small changes, big impacts in healthcare. Powered by chaos theory, we revolutionize personalized medicine with advanced AI solutions. Unleashing hidden patterns for transformative advancements.
                                </p>
                            </div>
                            <p className="footer-main__left-text mb-0">
                                © 2022 - deepmedai.com
                            </p>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="footer-main__right-text mt-4 mt-md-0">
                            <a
                                href="mailto:nishant.thakur@deepmedai.com"
                                className="footer-main__right-text__mail-text"
                            >
                                contact@deepmedai.com
                            </a>
                            <p className="footer-main__right-text__address">
                                23 Traverse De La Roseraie, Marseille-13007, France 
                            </p>
                            <div className="d-flex justify-content-between footer-main__right-text__apps mt-4 mt-md-5 flex-wrap">
                                <p><a href="https://twitter.com/DeepMedAI">Twitter </a></p>
                                <p><a href="https://www.facebook.com/profile.php?id=100093333761107">Facebook</a> </p>
                                <p>Instagram</p>
                                <p>Linkedin</p>
                            </div>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="footer-main__right-text mt-4 mt-md-0">
                            <h3>Site map</h3>
                            <Nav
                                className="ml-auto text-dark flex-ro text-wrap"
                                navbar
                            >
                                {links.map((link) => (
                                    <NavItem key={link.text} className="pr-2">
                                        {link.to &&
                                        location.pathname === "/" ? (
                                            <NavLink
                                                tag={Link}
                                                to={link.to}
                                                activeClass="active"
                                                spy={true}
                                                smooth={true}
                                                duration={500}
                                                className="cursor-pointer"
                                            >
                                                <small className="text-dark mb-0 fw--700 ff--Inter text-uppercase">
                                                    {link.text}
                                                </small>
                                            </NavLink>
                                        ) : link.to ? (
                                            <HashLink
                                                to={`/#${link.to}`}
                                                className="nav-link"
                                            >
                                                <small className="text-dark mb-0 fw--700 ff--Inter text-uppercase">
                                                    {link.text}
                                                </small>
                                            </HashLink>
                                        ) : (
                                            <NavLink href={link.href}>
                                                <small className="text-dark mb-0 fw--700 ff--Inter text-uppercase">
                                                    {link.text}
                                                </small>
                                            </NavLink>
                                        )}
                                    </NavItem>
                                ))}
                            </Nav>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Footer;
