import React from "react";

const SectionTitle = ({ subTitle, title, description = null }) => {
    return (
        <div className="mx-auto text-center section-title-container">
            <small className="section-sub-title text-uppercase ff--Inter text-theme-paragraph fw--400 fs--14">
                {subTitle}
            </small>
            <h2 className="text-dark fw--700 mt-3 ff-Roboto fs--48">{title}</h2>
            {description && <p className="section-description mt-4 ff--Inter fs--18 fw--400">{description}</p>}
        </div>
    );
};

export default SectionTitle;
